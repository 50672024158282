.fullModal {
  position: absolute;
  top: 0;
  z-index: 4;
  width: 100%;
  min-width: 1200px;
  height: 100vh;
  overflow: hidden;
  background-color: #fff;

  .mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
  }

  .cl3 {
    color: #333;
  }

  .font14 {
    font-size: 14px;
  }

  .ml10 {
    margin-left: 10px;
  }

  .flex1 {
    flex: 1;
    overflow: auto;
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .titleWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    padding: 8px 16px;
    font-size: 16px;
    background-color: white;
    border-bottom: 1px solid #f0f0f0;
    border-radius: 2px 2px 0 0;
    box-shadow: 0 -1px 0 0 #f0f0f0;
  }

  .titleContent {
    display: flex;
    align-items: center;
  }

  .title {
    margin-left: 8px;
    color: #333;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  .closeIcon {
    display: flex;
    align-items: center;
    width: 22px;
    height: 22px;
    color: #999ba4;
    font-size: 14px;
    cursor: pointer;
  }
}

.alignCenter {
  display: flex;
  align-items: center;
}

:global {
  .filterLabel {
    flex-shrink: 0;
    .alignCenter();
    padding-right: 4px;
    padding-left: 4px;
    transition: all 0.3s linear;

    h3 {
      font-weight: 400 !important;
      margin-bottom: 0 !important;
      font-size: 12px !important;
    }
  }

  .filterBoolean {
    .alignCenter();
    height: 32px;

    .ant-radio-group {
      .alignCenter();
      margin-left: 8px;
    }

    .ant-radio-wrapper {
      .alignCenter();
    }
  }

  .filterInput {
    .alignCenter();
  }

  .filterSelect {
    .alignCenter();
    :global {
      .ant-select .ant-select-selector .ant-select-selection-overflow {
        flex-wrap: nowrap;
      }
    }
  }

  .filterInputNumberGroup {
    .alignCenter();
    .ant-input-number {
      flex-grow: 1;
    }

    > span {
      padding-left: 4px;
      padding-right: 4px;
    }
  }

  .filterCascader {
    .alignCenter();
    .ant-cascader {
      flex: 1;
      overflow: hidden;
    }
  }

  .filterTreeSelect {
    .alignCenter();
    .ant-select {
      flex: 1;
    }
  }

  .filterDate {
    .alignCenter();
    .ant-picker {
      flex: 1;
    }
  }
}

.dateSelect {
  padding: 8px 0;

  :global {
    .ant-tag {
      margin-right: 4px;
      padding-right: 4px;
      padding-left: 4px;
    }
  }
}

.multipleInputWrapper {
  &.active {
    background: #ddebff;
  }

  .alignCenter();
  justify-content: center;
  width: 24px;
  height: 24px;
  background: #f3f3f3;
  border-radius: 2px;
  transition: all 0.3s linear;
}

.multipleInputFooter {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  height: 32px;
}

.filterSelectChooseAll {
  .alignCenter();
  justify-content: space-between;
  height: 40px;
  padding: 0 8px;
  border-top: 1px solid #e2e2e5;
}

.btn {
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 180px;
}

.contentBase {
  display: flex;
  align-items: center;
}

.contentBase,
.collapseContainer {
  :global {
    .filterLabel {
      justify-content: flex-end;
    }
  }
}

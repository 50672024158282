.searchListStructure {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .pd8 {
    padding: 8px;
  }

  .mt4 {
    margin-top: 4px;
  }

  .bgf {
    background: #fff;
  }

  .flex1 {
    flex: 1;
  }

  .filter {
    border-radius: 4px;
    box-shadow: 2px 2px 5px 0 rgba(221, 221, 221, 0.5);
  }

  .mainBtns {
    margin: 14px 0 10px;
  }
}
